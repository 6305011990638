<template>
  <div id="secondMiddle">
    <div class="middle_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>今日防疫数据</span>
      </div>
      <div class="histogram_chart">
        <div class="data_box">
          <div class="data_item_box">
            <div class="dataTitle">核验人数</div>
            <div class="dataNumber">
              {{ buildingCounts ? buildingCounts.checkDay : "0" }}
            </div>
            <img class="dataIcon" src="../../assets/second_01.png" alt="" />
          </div>
          <div class="data_item_box">
            <div class="dataTitle">异常体温</div>
            <div class="dataNumber">
              {{ buildingCounts ? buildingCounts.errorTemp : "0" }}
            </div>
            <img class="dataIcon" src="../../assets/second_03.png" alt="" />
          </div>
          <div class="data_item_box">
            <div class="dataTitle">异常健康码</div>
            <div class="dataNumber">
              {{ buildingCounts ? buildingCounts.errorHealthCode : "0" }}
            </div>
            <img class="dataIcon" src="../../assets/second_02.png" alt="" />
          </div>
        </div>
      </div>
    </div>

    <div class="middle_last_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>时段核验趋势</span>
      </div>
      <div class="histogram_chart">
        <div class="histogram_chart_box">
          <histogram />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import histogram from "../../components/echart/secondHistoram.vue";
export default {
  data() {
    return {
      buildingCounts: null,
    };
  },
  watch: {
    "$store.state.buildingCounts": {
      //监听 store 中值变化请求接口进行刷新
      handler: function (newVal) {
        if (newVal) {
          this.buildingCounts = newVal;
        }
      },
    },
  },
  components: {
    histogram,
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/secondPage/middleGroup.scss";
</style>