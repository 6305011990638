<template>
  <div id="secondLeft">
    <div class="left_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>总核验人次</span>
      </div>

      <div class="healthCode_box">
        <div class="healthCode_box_num">
          <div>{{buildingCounts?buildingCounts.checkHealthCodeSum:'0'}} <img src="../../assets/up_green.png" alt="" /></div>
          <div>健康码数量统计</div>
        </div>
      </div>

      <div class="temperature_box">
        <div class="healthCode_box_num">
          <div>{{buildingCounts?buildingCounts.checkTempSum:'0'}} <img src="../../assets/up_red.png" alt="" /></div>
          <div>体温测量统计</div>
        </div>
      </div>
    </div>

    <div class="left_second_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>核验人次趋势</span>
      </div>
      <brokenLine :buildingCheckCountDay='buildingCheckCountDay' />
     
    </div>

  </div>
</template>
<script>
import brokenLine from '../../components/echart/brokenLine'
export default {
  data() {
    return {
      buildingCounts:null,
      buildingCheckCountDay:[]
    };
  },
  watch:{
    "$store.state.buildingCounts": {
      //监听 store 中值变化请求接口进行刷新
      handler: function (newVal) {
        if (newVal) {
          this.buildingCounts = newVal;
        }
      },
    },
     "$store.state.buildingCheckCountDay": {
      //监听 store 中值变化请求接口进行刷新
      handler: function (newVal) {
        if (newVal) {
          this.buildingCheckCountDay = newVal
        }
      },
    },
  },
  components: {
      brokenLine,
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
  },
};
</script>
<style lang="scss">
@import "../../assets/scss/secondPage/leftGroup.scss";
</style>