<template>
  <div id="secondRight">
    <div class="right_first_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>测温设备</span>
        <span @click="toDevice()" class="checkDevice" >查看</span>
      </div>
      <div class="deviceInfoBox">
        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">正常设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number">
                {{ buildingCounts ? buildingCounts.deviceOnline : "0" }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>

        <div class="deviceInfo">
          <div class="deviceTitleBox">
            <div class="line"></div>
            <div class="deviceTitle">异常设备</div>
            <div class="line"></div>
          </div>
          <div class="deviceNumBox">
            <div class="deviceNum">
              <div class="number errorNumber">
                {{
                  buildingCounts
                    ? Number(buildingCounts.deviceSum) - Number(buildingCounts.deviceOnline)
                    : "0"
                }}
              </div>
              <div class="unit">台</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right_Second_box">
      <div class="title_box">
        <img src="../../assets/title_icon.png" alt="" />
        <span>实时监测</span>
      </div>
      <!-- <rollingList /> -->
      <animList/>
    </div>
  </div>
</template>
<script>
// import rollingList from "../../components/echart/rollingList";
import animList from '../../components/echart/AnimList.vue'
// import proportion from '../../components/echart/proportion'
export default {
  data() {
    return {
      buildingCounts: null,
    };
  },
  watch: {
    "$store.state.buildingCounts": {
      //监听 store 中值变化请求接口进行刷新
      handler: function (newVal) {
        if (newVal) {
          this.buildingCounts = newVal;
        }
      },
    },
  },
  components: {
    animList
    // proportion
  },
  mounted() {
    this.cancelLoading();
  },
  methods: {
    cancelLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    toDevice(){
      this.$router.push({name:'thirdPage'});
    }
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/secondPage/rightGroup.scss";
  .checkDevice{
        color: #26befe !important;
        cursor:pointer;
    }
</style>