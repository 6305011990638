
<template>
  <div class="echarts">
    <div id="brokenLine"></div>
  </div>
</template>
 
<script>
import Echarts from "echarts";
import { echartsLoop, tooltipConfig } from "../../util/config";
export default {
  props: {
    buildingCheckCountDay: Array,
  },
  data() {
    return {
      myChart_brokenLine: {},
    };
  },
  watch: {
    "$store.state.buildingCheckCountDay": {
      handler: function (newVal) {
        this.countList = newVal;

        this.updateData();
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.loadEchart();
      echartsLoop(this.myChart_brokenLine, 2000, 0);
    });
  },
  mounted() {
    let _this = this;
    window.addEventListener(
      "resize",
      () => _this.myChart_brokenLine.resize(),
      false
    );
  },
  methods: {
    loadEchart() {
      let _this = this;
      this.myChart_brokenLine = Echarts.init(
        document.getElementById("brokenLine")
      );
      this.myChart_brokenLine.setOption({
         textStyle: {
          fontSize: 15,
          color: "#fff",
        },
        color: ["#1956a6"],
        grid: {
          left: "0",
          right: "5%",
          bottom: "5%",
          top: "15%",
          backgroundColor: "rgba(0,38,124,0.4)",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#1f48b4"],
              width: 1,
              type: "solid",
            },
          },
          data: this.getXList(),
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2249b8", //左边线的颜色
              width: "2", //坐标线的宽度
            },
          },
        },
        yAxis: {
          type: "value",
          splitLine: {
            show: true,
            lineStyle: {
              color: ["#1f48b4"],
              width: 1,
              type: "solid",
            },
            textStyle: {
              color: "#fff",
            },
          },
          axisLine: {
            lineStyle: {
              type: "solid",
              color: "#2249b8", //左边线的颜色
              width: "2", //坐标线的宽度
            },
            textStyle: {
              color: "#fff",
            },
          },
        },
        tooltip: {
          ...tooltipConfig,
          trigger: "item",
          formatter: "{b}<br>核验人次: {c}人",
        },
        series: [
          {
            data: this.getDataList(),
            type: "line",
            label: {
              normal: {
                show: true,
                postion: "top",
                textStyle: {
                  color: "#14b5f2",
                  fontSize: 16,
                },
              },
            },
            areaStyle: {
              normal: {
                color: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#132eb1", // 0% 处的颜色
                    },
                    {
                      offset: 0.5,
                      color: "rgba(19,37,165,.5)", // 100% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "rgba(19,37,165,.1)", // 100% 处的颜色
                    },
                  ],
                  globalCoord: false, // 缺省为 false
                },
              },
            },
          },
        ],
      });
      window.addEventListener(
        "resize",
        () => _this.myChart_brokenLine.resize(),
        false
      );
    },

    updateData() {
      let xDataList = this.getXList();
      let dataList = this.getDataList();

      this.myChart_brokenLine.setOption({
        xAxis: {
          data: xDataList,
        },
        series: [
          {
            data: dataList,
          },
        ],
      });
    },
    getXList() {
      if (this.buildingCheckCountDay && this.buildingCheckCountDay.length > 0) {
        return this.buildingCheckCountDay.map((item) => item.countDate);
      }

      return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
    },

    getDataList() {
      if (this.buildingCheckCountDay && this.buildingCheckCountDay.length > 0) {
        return this.buildingCheckCountDay.map((item) => item.checkAdd);
      }
      return [];
    },
  },
};
</script>
 
<style>
#brokenLine {
  width: 95%;
  height: 48vh;
}
</style>